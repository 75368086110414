import { memo } from "react";
import Container from "../common/Container";
import DesktopMegaMenu from "./DesktopMegaMenu";
import MobileMegaMenu from "./MobileMegaMenu";

function MegaMenu() {
  return (
      <Container
        large
        className="bg-primary-black fixed w-full z-20"
      >
        <div className="block xl:hidden">
          <MobileMegaMenu />
        </div>
        <div className="hidden xl:block">
          <DesktopMegaMenu />
        </div>
      </Container>
  );
}

export default memo(MegaMenu);
