import { button_value, LINK_OF_ALL_PAGES } from "const/common";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import Accordion from "./Accordion";

const MobileMegaMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [openChildAccordion, setOpenChildAccordion] = useState(null);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleToggle = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? null : accordionId);
    setOpenChildAccordion(null);
  };
  const handleChildToggle = (accordionId) => {
    setOpenChildAccordion(
      openChildAccordion === accordionId ? null : accordionId
    );
  };
  return (
    <header className="bg-primary-black flex justify-between flex-wrap items-center py-3 md:py-5">
      <div className="fs-0 max-w-36 md:max-w-full">
        <Link href={LINK_OF_ALL_PAGES.mainPages.home}>
          <a>
            <Image
              src="/assets/common/unt-logo.svg"
              width={236}
              height={32}
              alt="logo"
            />
          </a>
        </Link>
      </div>
      <button
        className="xl:hidden p-3 md:p-6 focus:outline-none bg-primary-yellow"
        onClick={toggleMenu}
      >
        <div
          className={`hamburger flex flex-col justify-center ${
            isOpen ? "open" : ""
          }`}
        >
          <span className="block w-4 h-0.5 md:w-6 md:h-1 bg-white mb-1 mx-auto transition-transform"></span>
          <span className="block w-4 h-0.5 md:w-6 md:h-1 bg-white mb-1 mx-auto transition-transform"></span>
          <span className="block w-4 h-0.5 md:w-6 md:h-1 bg-white mx-auto transition-transform"></span>
        </div>
      </button>
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } xl:flex xl:items-center xl:w-auto w-full transition-transform duration-1000 ease-in h-svh max-h-svh overflow-y-auto hide-scrollbar`}
      >
        <nav className="px-4 pt-12">
          <Accordion
            title="Services"
            pageLink={LINK_OF_ALL_PAGES.services.servicesMain}
            isOpen={openAccordion === "services"}
            onToggle={() => handleToggle("services")}
          >
            <Accordion
              title="Custom Software Development"
              pageLink={LINK_OF_ALL_PAGES.services.customSoftwareDevelopment}
              isOpen={openChildAccordion === "customSoftwareDevelopment"}
              onToggle={() => handleChildToggle("customSoftwareDevelopment")}
            >
              <div>
                <Link
                  href={
                    LINK_OF_ALL_PAGES.services.webApplicationDevelopmentServices
                  }
                >
                  <a className="text-base font-normal text-white pb-5 pl-2.5 block">
                    <span>Web App Development</span>
                  </a>
                </Link>
                <Link
                  href={
                    LINK_OF_ALL_PAGES.services
                      .mobileApplicationDevelopmentServices
                  }
                >
                  <a className="text-base font-normal text-white pb-5 pl-2.5 block">
                    <span>Mobile App Development</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.cloudAndDevopsServices}>
                  <a className="text-base font-normal text-white pb-5 pl-2.5 block">
                    <span>Cloud & DevOps</span>
                  </a>
                </Link>
                <Link
                  href={
                    LINK_OF_ALL_PAGES.services
                      .enterpriseSoftwareDevelopmentServices
                  }
                >
                  <a className="text-base font-normal text-white pb-5 pl-2.5 block">
                    <span>Enterprise Software Development</span>
                  </a>
                </Link>
              </div>
            </Accordion>
            <Link href={LINK_OF_ALL_PAGES.services.uiUXDesignServices}>
              <a className="pb-5 text-base font-normal text-white block">
                <span>UI-UX Designing</span>
              </a>
            </Link>
            <Link href={LINK_OF_ALL_PAGES.services.digitalMarketing}>
              <a className="pb-5 text-base font-normal text-white block">
                <span>Digital Marketing</span>
              </a>
            </Link>
            <Link href={LINK_OF_ALL_PAGES.services.itConsultationAssistance}>
              <a className="pb-5 text-base font-normal text-white block">
                <span>IT Consultation</span>
              </a>
            </Link>
            <Link href={LINK_OF_ALL_PAGES.services.staffAugmentation}>
              <a className="pb-5 text-base font-normal text-white block">
                <span>IT Staff Augmentation</span>
              </a>
            </Link>
            <div className="text-center">
              <Link href={LINK_OF_ALL_PAGES.services.servicesMain}>
                <a className="py-4 px-7 text-primary-yellow border border-solid border-primary-yellow text-base font-normal rounded inline-block my-5">
                  Explore All Services
                </a>
              </Link>
            </div>
          </Accordion>
          <Accordion
            title="Data Solution"
            isOpen={openAccordion === "dataSolution"}
            onToggle={() => handleToggle("dataSolution")}
          >
            <Accordion
              title="Data Scraping"
              pageLink={LINK_OF_ALL_PAGES.mainPages.dataSolution}
              isOpen={openChildAccordion === "dataScraping"}
              onToggle={() => handleChildToggle("dataScraping")}
            >
              <Link
                href={LINK_OF_ALL_PAGES.services.enterPriseWebScrapingService}
              >
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Enterprise Web Scraping</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.mobileAppScrapingServices}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Mobile App Scraping</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.webScrapingApi}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Web Scraping API</span>
                </a>
              </Link>
            </Accordion>
            <div className="text-center">
              <Link href={LINK_OF_ALL_PAGES.mainPages.dataSolution}>
                <a className="py-4 px-7 text-primary-yellow border border-solid border-primary-yellow text-base font-normal rounded inline-block my-5">
                  Get Free Sample Data
                </a>
              </Link>
            </div>
          </Accordion>
          <Accordion
            title="Hire Developers"
            pageLink={LINK_OF_ALL_PAGES.mainPages.expertise}
            isOpen={openAccordion === "hireDevelopers"}
            onToggle={() => handleToggle("hireDevelopers")}
          >
            <Accordion
              title="Full Stack"
              isOpen={openChildAccordion === "FullStack"}
              onToggle={() => handleChildToggle("FullStack")}
            >
              <Link
                href={LINK_OF_ALL_PAGES.services.hireMeanDeveloper}
                isOpen={openChildAccordion === "fullStack"}
                onToggle={() => handleChildToggle("fullStack")}
              >
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Mean Stack Developer</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.hireMernDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Mern Stack Developer</span>
                </a>
              </Link>
            </Accordion>
            <Accordion
              title="Front End"
              isOpen={openChildAccordion === "frontEnd"}
              onToggle={() => handleChildToggle("frontEnd")}
            >
              <Link href={LINK_OF_ALL_PAGES.services.hireReactJSDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>React JS Developer</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.hireAngularDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Angular JS Developer</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.hireNextJSDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Next JS Developer</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.hireVueJSDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Vue JS Developer</span>
                </a>
              </Link>
            </Accordion>
            <Accordion
              title="Back End"
              isOpen={openChildAccordion === "backEnd"}
              onToggle={() => handleChildToggle("backEnd")}
            >
              <Link href={LINK_OF_ALL_PAGES.services.hireNodeJSDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Node JS Developer</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.hireRoRDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Ruby on Rails Developer</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.hirePythonDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Python Developer</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.hireGolangDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Golang Developer</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.hireLaravelDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Laravel Developer</span>
                </a>
              </Link>
            </Accordion>
            <Accordion
              title="Mobile App"
              isOpen={openChildAccordion === "mobileApp"}
              onToggle={() => handleChildToggle("mobileApp")}
            >
              <Link href={LINK_OF_ALL_PAGES.services.hireAndroidDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Android Developer</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.hireiOSDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>IOS Developer</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.hireKotlinDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Kotlin Developer</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.hireSwiftDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Swift Developer</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.hireFlutterDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Flutter Developer</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.hireReactNativeDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>React Native Developer</span>
                </a>
              </Link>
            </Accordion>
            <Accordion
              title="Open Source"
              isOpen={openChildAccordion === "openSource"}
              onToggle={() => handleChildToggle("openSource")}
            >
              <Link href={LINK_OF_ALL_PAGES.services.hireShopifyDeveloper}>
                <a className="pb-5 text-base font-normal text-white block">
                  <span>Shopify Developer</span>
                </a>
              </Link>
            </Accordion>
            <div className="text-center">
              <Link href={LINK_OF_ALL_PAGES.mainPages.expertise}>
                <a className="py-4 px-7 text-primary-yellow border border-solid border-primary-yellow text-base font-normal rounded inline-block my-5">
                  EXPLORE ALL
                </a>
              </Link>
            </div>
          </Accordion>
          <Link href={LINK_OF_ALL_PAGES.mainPages.portfolio}>
            <a className="text-base font-normal text-white pb-5 block">
              Portfolio
            </a>
          </Link>
          <Link href={LINK_OF_ALL_PAGES.mainPages.aboutUltroneous}>
            <a className="text-base font-normal text-white pb-5 block">
              About Us
            </a>
          </Link>
          <Link href={LINK_OF_ALL_PAGES.mainPages.blog}>
            <a className="text-base font-normal text-white pb-5 block">Blog</a>
          </Link>
          <div className="text-center">
            <Link href={LINK_OF_ALL_PAGES.mainPages.contactUs}>
              <a className="text-base font-normal text-white py-4 px-7 bg-primary-yellow rounded-lg mt-16 mb-14 inline-block">
                {button_value}
              </a>
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default MobileMegaMenu;
